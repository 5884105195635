@font-face {
  font-family: "CabinetGroteskBold";
  src: local("CabinetGroteskBold"),
    url("./assets/fonts/Cabinet_Grotesk/CabinetGrotesk-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "CabinetGroteskRegular";
  src: local("CabinetGroteskRegular"),
    url("./assets/fonts/Cabinet_Grotesk/CabinetGrotesk-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "CabinetGroteskVariable";
  src: local("CabinetGroteskVariable"),
    url("./assets/fonts/Cabinet_Grotesk/CabinetGrotesk-Variable.ttf") format("truetype");
}

@font-face {
  font-family: "InterExtrabold";
  src: local("InterExtrabold"),
    url("./assets/fonts/Inter/Inter-Black.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "InterBold";
  src: local("InterBold"),
    url("./assets/fonts/Inter/Inter-SemiBold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "InterRegular";
  src: local("InterRegular"),
    url("./assets/fonts/Inter/Inter-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Cyrene";
  src: url("./assets/fonts/Cyrene/CyreneRegular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;

  /* SCROLLBAR FOR FIREFOX */
  scrollbar-width: auto;
  scrollbar-color: #ff6a00 #e6e6e6;
}

a {
  text-decoration: none;
  color: inherit;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; 
    */
  font-family: "DM Sans", "Syne", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #F9F9F9; */
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-family: "Syne";
  font-size: 58px;
  color: "#262626";
}

h2 {
  font-family: "Syne";
  font-size: 48px;
  color: "#262626";
}

h3 {
  font-family: "Syne";
  font-size: 32px;
  color: "#262626";
}

h4 {
  font-family: "Syne";
  font-size: 20px;
  color: "#262626";
}

h5 {
  font-family: "Syne";
  font-size: 23px;
  color: "#262626";
}

h6 {
  font-family: "Syne";
  font-size: 24px;
  color: "#262626";
}

h7 {
  font-family: "Syne";
  font-size: 18px;
  color: "#262626";
}

article {
  font-family: "Syne";
  font-size: 32px;
  color: "#262626";
}

p {
  font-family: "DM Sans";
  font-size: 14px;
  color: "#262626";
  font-weight: "400";
}

/* SCROLLBAR FOR CHROME / SAFARI / EDGE LOL */
::-webkit-scrollbar {
  width: 0;
}

/* SCROLLBAR FOR CHROME / SAFARI / EDGE LOL */
.no-scrollbar::-webkit-scrollbar {
  width: 0px;
  overflow-x: "hidden";
  overflow-y: "hidden";
}

.no-scrollbar::-webkit-scrollbar-track {
  background: #e6e6e6;
}

.no-scrollbar::-webkit-scrollbar-thumb {
  background: #ff6a00;
  border-radius: 5px;
}

.webgl {
  position: fixed !important;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  z-index: -1;
}